






import LabSpectroscopyTube, {SpectroscopyTubeElement} from '../simulations/LabSpectroscopyTube.vue';
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import {defineComponent} from '@vue/composition-api';
import TaskState from '../../../task-states/models/TaskState';
import {VariableValueType} from '@/task-states/types/RawTaskVariableValue';
import LabTask from './labs/LabTask.vue';
const PREFIX_URL = '/assets/img/spectro_images';
const UNKNOWN_ELEMENT_COLORS: Record<number, string> = {
  1: '#F1AEF0',
  2: '#FFFFFF',
  3: '#F9DBC9',
  4: '#F9F2FF',
  5: '#FF3C00',
};

export type TaskStateType = TaskState<{
  unknownElementId: VariableValueType.IntegerValue;
}>;

export default defineComponent({
  name: 'SpectroscopyTubeSIM',
  components: {LabSpectroscopyTube, LabTask},
  mixins: [DynamicQuestionMixin()],
  computed: {
    typedState(): TaskStateType {
      return this.taskState as TaskStateType;
    },
    unknownElementId(): number {
      return this.typedState.getValueBySymbol('unknownElementId')?.content?.value as number;
    },
    lamps(): SpectroscopyTubeElement[] {
      return [
        {
          element: 'Sodium',
          color: '#f59e42',
          spectrumUrl: `${PREFIX_URL}/sodium.png`,
        },
        {
          element: 'Hydrogen',
          color: '#F0C5F7',
          spectrumUrl: `${PREFIX_URL}/hydrogen.png`,
        },
        {
          element: 'Mercury',
          color: '#D6FFFF',
          spectrumUrl: `${PREFIX_URL}/mercury.png`,
        },
        {
          element: `Unknown ${this.unknownElementId}`,
          color: UNKNOWN_ELEMENT_COLORS[this.unknownElementId],
          spectrumUrl: `${PREFIX_URL}/unknown${this.unknownElementId}.png`,
        },
      ];
    },
  },
});
