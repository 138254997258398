









import LabSim from '@/tasks/components/simulations/LabSim.vue';
import {computed, defineComponent, PropType} from '@vue/composition-api';

export interface SpectroscopyTubeElement {
  element: string;
  color: string;
  spectrumUrl: string;
}

export default defineComponent({
  name: 'LabSpectroscopyTube',
  components: {LabSim},
  inheritAttrs: false,
  props: {
    lamps: {
      type: Array as PropType<SpectroscopyTubeElement[]>,
      default: [],
    },
  },
  setup(props) {
    const stringifiedLamps = computed(() => {
      return JSON.stringify(props.lamps);
    });

    return {
      stringifiedLamps,
    };
  },
});
